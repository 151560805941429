import React, { useEffect } from "react";
import Contactus from "../component/Contactus";
import "../styles/Contact.css";
import { motion, useIsPresent } from "framer-motion";

const Contact = () => {
  const isPresent = useIsPresent();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <section>
      <Contactus />
      <motion.div
        className="privacy-screen"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
      ></motion.div>
    </section>
  );
};

export default Contact;

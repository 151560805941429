import React, { useEffect } from "react";
import Works from "../component/Works";
import "../styles/About.css";
import photography from "../asset/photography.png";
import laptop from "../asset/laptop.png";
import cafe from "../asset/cafe.png";
import Team from "../component/Team";
import { motion, useAnimation, useIsPresent } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { staggerContainer, zoomIn } from "../animation";
import { Link } from "react-router-dom";
import ClientsTestimonials from "../component/ClientsTestimonials";

const About = () => {
  const isPresent = useIsPresent();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0.5, 0.75, 0.95],
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start("onscreen");
    }
    if (!inView) {
      animation.start("offscreen");
    }
  }, [animation, inView]);
  return (
    <>
      <section>
        <div className="about-hero"></div>
        <section className="brand-identity">
          <div className="container">
            <h1>Who We Are</h1>
            <p>
              The Creative Ally will function as a Creative Content Creation,
              Marketing, Strategy and Brand management outfit that focuses on
              the brand as a living entity as regards these different
              touch-points we already listed.
            </p>
            <p>
              The Creative Ally will serve as an agency that makes marketing,
              strategy and brand experience seamless through creative tools and
              resources Our agency aims to reach current and prospective clients
              across every potential digital channel to maximize consumer
              interaction with your brand.
            </p>
          </div>
        </section>
        <div className="gallery-content">
          <div className="gallery-content-container">
            <motion.div
              ref={ref}
              initial="offscreen"
              variants={staggerContainer}
              animate={animation}
              className="galleries"
            >
              <motion.div variants={zoomIn} className="gallery gallery-img1">
                <img src={photography} alt="photography" />
              </motion.div>
              <motion.div variants={zoomIn} className="gallery gallery-img2">
                <img src={laptop} alt="laptop" />
              </motion.div>
              <motion.div variants={zoomIn} className="gallery gallery-img3">
                <img src={cafe} alt="cafe" />
              </motion.div>
            </motion.div>
            <div className="gallery-text-content-wrapper">
              <div className="gallery-text-content">
                <div className="container">
                  <h2>
                    Thought. <br /> Creation. Reality
                  </h2>
                  <p>
                    These three harness the build-up of our processes, intrinsic
                    culture and nature of our business. This will sit in the
                    personal campaigns as a trade mark of the brand.
                  </p>
                  <Link to="/contact-us"> Contact Us Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Team /> */}
        <Works />
        <ClientsTestimonials />
      </section>
      <motion.div
        className="privacy-screen"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
      ></motion.div>
    </>
  );
};

export default About;

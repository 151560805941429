import React, { useEffect } from "react";
import strategy from "../asset/strategy.svg";
import pencil from "../asset/pencil.svg";
import content from "../asset/content.svg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  staggerContainer,
  fadeIn,
  zoomIn,
  fadeUp,
  slideIn,
} from "../animation";
import { Link } from "react-router-dom";

const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0.2, 0.75, 0.95],
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start("onscreen");
    }
    if (!inView) {
      animation.start("offscreen");
    }
  }, [animation, inView]);

  return (
    <motion.section
      ref={ref}
      initial="offscreen"
      variants={staggerContainer}
      animate={animation}
      className="services"
    >
      <div className="services-content">
        <div className="services-header-content">
          <div className="services-header-content-flex container">
            <div>
              <motion.h2 variants={fadeUp}>Our Services</motion.h2>
              <motion.p variants={fadeIn}>
                We offer customized game plan for your brand strategy, to ensure
                an effective campaign execution
              </motion.p>
            </div>
            <motion.span variants={zoomIn}>
              <Link to="/contact-us" className="services-cta-btn">
                Contact Us Now
              </Link>
            </motion.span>
          </div>
        </div>
        <div className="services-body-content container">
          <div className="services-cards">
            <div className="services-card">
              <div className="services-card-header">
                <motion.img variants={slideIn} src={strategy} alt="strategy" />
                <motion.h3 variants={fadeUp}>
                  Marketing <br /> & strategy{" "}
                </motion.h3>
              </div>
              <ul className="services-list">
                <motion.li variants={fadeIn}>
                  {" "}
                  Digital Marketing strategy
                </motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  Digital Marketing Campaign{" "}
                </motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  Search Engine Optimization
                </motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  Reporting using analytics data{" "}
                </motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  Strategy & Distribution{" "}
                </motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  TV, Radio, Playlisting{" "}
                </motion.li>
              </ul>
            </div>
            <div className="services-card">
              <div className="services-card-header">
                <motion.img
                  variants={slideIn}
                  src={pencil}
                  alt="branding and management"
                />
                <motion.h3 variants={fadeUp}>
                  Branding <br /> Management
                </motion.h3>
              </div>
              <ul className="services-list">
                <motion.li variants={fadeIn}> Brand Development</motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  Management & Consultation{" "}
                </motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  Creative Direction & Media Planning
                </motion.li>
                <motion.li variants={fadeIn}>
                  {" "}
                  Reputation & Community Management{" "}
                </motion.li>
              </ul>
            </div>
            <div className="services-card">
              <div className="services-card-header">
                <motion.img
                  variants={slideIn}
                  src={content}
                  alt="creative content"
                />
                <motion.h3 variants={fadeUp}>
                  Creative <br /> Content{" "}
                </motion.h3>
              </div>
              <ul className="services-list">
                <motion.li variants={fadeIn}>
                  {" "}
                  Graphics & Brand Design
                </motion.li>
                <motion.li variants={fadeIn}> Motion Graphics </motion.li>
                <motion.li variants={fadeIn}> Video Production</motion.li>
                <motion.li variants={fadeIn}> Animation FX </motion.li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Services;

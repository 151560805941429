import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Testimonials.css";
import { testimonials } from "../testimonials";
import { ArrowLeft, ArrowRight } from "../icons";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { staggerContainer, fadeIn } from "../animation";

const ClientsTestimonials = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0.2, 0.75, 0.95],
  });
  const animation = useAnimation();
  const slider = React.useRef(null);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (inView) {
      animation.start("onscreen");
    }
    if (!inView) {
      animation.start("offscreen");
    }
  }, [animation, inView]);
  return (
    <motion.section
      ref={ref}
      initial="offscreen"
      variants={staggerContainer}
      animate={animation}
    >
      <div className="client_testimonial_header">
        <motion.h4 variants={fadeIn}>Testimonials</motion.h4>
        <motion.p variants={fadeIn}>See what our clients say.</motion.p>
      </div>
      <div className="client_testimonials_section">
        <div className="client_testimonials container">
          <Slider ref={slider} {...settings}>
            {testimonials.map((testimonial, i) => {
              return (
                <article key={i} className="testimonial_content">
                  <div>
                    <div>
                      {testimonial.testimonial.map((comment, idx) => {
                        return (
                          <p className="testimonial_content-text" key={idx}>
                            {comment}
                          </p>
                        );
                      })}
                    </div>
                    <p className="testimonial_content-author">
                      — {testimonial.name}
                    </p>
                    <p className="testimonial_content-position">
                      {testimonial.position}
                    </p>
                  </div>
                </article>
              );
            })}
          </Slider>
        </div>
        <div className="client_testimonial_arrow">
          <button onClick={() => slider?.current?.slickPrev()}>
            <ArrowLeft />
          </button>
          <button onClick={() => slider?.current?.slickNext()}>
            <ArrowRight />
          </button>
        </div>
      </div>
    </motion.section>
  );
};

export default ClientsTestimonials;

import React, { useEffect } from "react";
// import clients from "../asset/client.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { staggerContainer, fadeIn, fadeUp, slideIn } from "../animation";
import { clients } from "../data";

const Works = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0.2, 0.75, 0.95],
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start("onscreen");
    }
    if (!inView) {
      animation.start("offscreen");
    }
  }, [animation, inView]);

  return (
    <motion.section
      ref={ref}
      initial="offscreen"
      variants={staggerContainer}
      animate={animation}
      className="works"
    >
      <div className="works-content container">
        <div className="work-content-header">
          <motion.h4 variants={fadeUp}>
            A few brands we're proud to have worked with
          </motion.h4>
          <motion.p variants={fadeIn}>Over 400+ for the past 5 years</motion.p>
        </div>
        {/* <motion.div variants={slideIn} className="img-wrapper">
          <img src={clients} alt="clients" />
        </motion.div> */}
        <div className="logo-card-wrapper">
          {clients.map((client, i) => {
            return (
              <motion.div className="logo-card" key={i} variants={slideIn}>
                <img src={client.img} alt={client.route_name} />
              </motion.div>
            );
          })}
        </div>
      </div>
    </motion.section>
  );
};

export default Works;

import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CaseStudies from "../pages/CaseStudies";
import MobileMenu from "../component/MobileMenu";
import Footer from "../component/Footer";
import DesktopMenu from "../component/DesktopMenu";
import CaseStudy from "./CaseStudy";
import ServicesPage from "./ServicesPage";
import Contact from "./Contact";
import Blog from "./Blog";
import Error from "./Error";
import "../styles/App.css";
import { AnimatePresence } from "framer-motion";

const App = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [isOpen]);

  return (
    <main style={{ overflow: `${isOpen && "hidden"}` }}>
      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      <DesktopMenu />
      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/case-study/:name" element={<CaseStudy />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </main>
  );
};

export default App;

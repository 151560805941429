import React, { useEffect } from "react";
import Services from "../component/Services";
import "../styles/App.css";
import { motion, useIsPresent } from "framer-motion";
import Works from "../component/Works";
import ClientsTestimonials from "../component/ClientsTestimonials";

const ServicesPage = () => {
  const isPresent = useIsPresent();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <section className="services-page">
        <Services />
        <Works />
        <ClientsTestimonials />
      </section>
      <motion.div
        className="privacy-screen"
        initial={{ scaleX: 1 }}
        animate={{
          scaleX: 0,
          transition: { duration: 0.5, ease: "circOut" },
        }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
      ></motion.div>
    </>
  );
};

export default ServicesPage;

import React, { useEffect } from "react";
import SingleCaseStudy from "../component/CaseStudy";
import "../styles/CaseStudy.css";
import { motion, useIsPresent } from "framer-motion";

const CaseStudy = () => {
  const isPresent = useIsPresent();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <SingleCaseStudy />
      <motion.div
        className="privacy-screen"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
      ></motion.div>
    </>
  );
};

export default CaseStudy;

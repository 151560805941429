import React, { useEffect } from "react";
import heroimg from "../asset/heroimg.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { staggerContainer, fadeIn, zoomIn } from "../animation";
import { Link } from "react-router-dom";

const Hero = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0.15, 0.75, 0.95],
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start("onscreen");
    }
    if (!inView) {
      animation.start("offscreen");
    }
  }, [animation, inView]);

  return (
    <section className="hero">
      <div className="container">
        <motion.div
          // ref={ref}
          // initial="offscreen"
          // variants={staggerContainer}
          // animate={animation}
          className="hero-content"
        >
          <div className="hero-text-content">
            <motion.h1 variants={fadeIn}>
              We're maximizing consumer interaction for brands globally.
            </motion.h1>
            <motion.p variants={fadeIn}>
              The Creative Ally is a marketing, PR and Communications agency
              that aims to reach your specific audience and maximize interaction
              with your brand across various digital channels.
            </motion.p>
            <div className="hero-cta-btns">
              <motion.span variants={fadeIn}>
                <Link to="/case-studies" className="hero-cta hero-cta-btn1">
                  Browse Case Studies
                </Link>
              </motion.span>
              <motion.span variants={fadeIn}>
                <Link to="/services" className="hero-cta hero-cta-btn2">
                  See Our Services
                </Link>
              </motion.span>
            </div>
          </div>
          <div className="hero-img-content">
            <motion.img variants={zoomIn} src={heroimg} alt="african-man" />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;

import patricia from "./asset/patricia.png";
import llyod from "./asset/llyod.webp";
import tigerwit from "./asset/tigerwit.PNG";
import icreate from "./asset/icreate.png";
import clout from "./asset/clout.png";
import aux from "./asset/aux_logo.PNG";

export const clients = [
  {
    id: 1,
    route_name: "llyod insurance",
    case_study_title: "94 million impressions with a unique Digital Strategy",
    name: "Llyod Insurance - dive in fest ",
    img: llyod,
    text: "Lloyd’s Insurance UK hosted the largest global festival for Diversity and Inclusion in insurance from the 24th September 2020.",
    case_study: {
      intro:
        "Lloyd’s Insurance UK was hosted the largest global festival for Diversity and Inclusion in insurance from the 24th September 2020. In replicating this festival in Nigeria (TheDive In Nigeria Festival), senior professional women and men in the Insurance andFinance Sector were invited to join the conversation addressing inclusion, age and gender diversity in the Nigerian workplace, Finance and Insurance especially.",
    },
    challenges: [
      "Recognizing us as one of the leading agencies with a diverse audience, they decided to work with you and your team in taking the message about this event far and wide- create awareness and spur conversations around diversity and inclusion in the Nigerian workplace, drive registration and have the event trend on the Nigerian Twitter starting from Monday, September 21 through the event itself on Thursday, September 24.",
    ],
    process: {
      paragraph1:
        "On Monday, 21st of September, 2020, we began the Twitter campaign for the Diversion and Inclusion Festival (DIVE Fest), the campaign ran from the 21st September to the main event day, 24th September 2020. The aim of this campaign was to:",
      list: [
        "Create awareness and spur conversations around diversity and inclusion in the Nigerian workplace",
        "Have general conversations around Insurance in Nigeria",
        "Drive registration",
        "Give live updates from the event itself",
        "Gain followers and engagements for the @GIFest_ng2020 page",
        "Get the topic of the event to be a major trending topic in the country",
      ],
      otherParagraphs: [
        "For the Dive In Nigeria campaign done, a unique strategy was created each day to achieve the aims of each specific day. On the first day the major focus was sparking up conversations about Diversity and Inclusion while briefly talking about how Insurance is beneficial to the regular person/ business.",

        "The second day, an indepth look at Diversity and Inclusion in the Nigerian workplace was done through the contents and conversation starters put out.",
        "The third day's focus was on the speakers and most of the contents revolved around them (while continuing the conversation from the previous days). Letters that notable women in the industry were also put out as creative content to this effec ",

        "On all these days, the registration link was put out so as to get as many as possibleinvolved in the event itself.",
      ],
    },
    results: [
      "This campaign was executed using the 'DiveInNigeria' and 'Global Inclusion 2020' hashtags. After tracking using analytics tools, there were more that 3913 tweets from 1,184 users on the #DiveInNigeria hashtag. It amassed a 94.7 million impact, a reach of 15.4 million people and a 80% sentiment score. It was the number one trending topic and had the highest impact on Twitter Nigeria for hours ",
    ],
  },
  {
    id: 2,
    route_name: "patricia",
    case_study_title: "Worldwide trend on brand activation",
    name: "Patricia",
    img: patricia,
    text: "Patricia is a technology-driven alternative payments platform where merchants and consumers can transact with new digital currencies.",
    case_study: {
      intro:
        "Patricia is a technology-driven alternative payments platform where merchants and consumers can transact with new digital currencies. Their mission is to usher Africa into the digital age with our technology, innovation, and alternative payment solutions.",
    },
    challenges: [
      "Patricia Technologies had been a media partner on the prolific Big Brother Naija show since the start of the 2020 season and on 8th of August, the Patricia Luxury Bitcoin Debit Card was unveiled in the house. The execution of the BBNaija feature day involved the quiz and the tasks performed by the Housemates, while they wore Patricia merchandise for the entire day. This was meant to be a massive day for the brand and the promotional efforts were meant to be felt in the Social media space. The aim of this campaign was to spark up the conversations of actions going on in the house (related to Patricia), drive engagements to the Patricia Switch page, get people more familiar with the brand, get people acquainted with the hashtag used to be tracked and to get multiple downloads of the Patricia Technologies app.",
    ],
    process: {
      paragraph1: "",
      list: [],
      otherParagraphs: [
        "The hype and conversation revolved around the Patricia- Big Brother Naija quizzes, tasks, gifts and product features for the day. The campaign was to generate top of the mind awareness from users, give rise to massive engagements, spark up conversations and promote downloads for the Patricia App. Within minutes of contents being posted, the hashtag started trending at number three on, remained strongly on the table for hours and peaked at number one in Nigeria. It became the major talking point on the timeline which lasted hours..",

        "The day began with the suggestion of the #PatriciaXBBNaija hashtag to be used, creation of the numerous contents to be used and the direction the campaign to go during the entirety of the trend as the Patricia-BBNaija product feature was going on. The trend was executed and multiple engagements were done on the PatriciaSwitch account.",
      ],
    },
    results: [
      "Apart from the hashtag trending at number one in Nigeria for hours, the #PatriciaXBBNaija hashtag also trended in Puerto Rico and WORLDWIDE. In Puerto Rico, the hashtag trended at number 45 and WORLDWIDE, the hashtag trended at number 42.",

      "This shows the massive impact of the trend done on the day, which transcended well outside Nigeria.",

      "The #PatriciaXBBNaija hashtag trended Worldwide, in Nigeria as well as in Puerto Rico. From the analytics gitten, there were 12,000+ tweets from 5,021 users on thE hashtag. It amassed a 41.2 million impact and a reach of 15.5 million people.",
    ],
  },
  {
    id: 3,
    route_name: "tigerwit",
    case_study_title: "tigerwit",
    name: "Tigerwit",
    img: tigerwit,
    text: "Tigerwit is a financial technology firm founded with the aim of bringing technological solutions to the financial industry.",
    case_study: {
      intro:
        "Tigerwit is a financial technology firm founded with the aim of bringing technological solutions to the financial industry. They are the official foreign exchange partner of Liverpool Football club",
    },
    challenges: [
      "Tigerwit approached us to help create a media strategy to increase their customer base in Africa using Radio Airplays at Strategic peak times.",
    ],
    process: {
      paragraph1: "",
      list: [],
      otherParagraphs: [
        "The script for the jingle was created. It was conversational and portrayed the intended message in a way convincing enough to the target audience. We also figured out which time would work best for the jingle to be airplayed, then executed it.",
      ],
    },
    results: [
      "The radio jingle was aired on a station with a weekly average listener figure of506,000 and this brought about returns for the brand.",
    ],
  },
  {
    id: 4,
    route_name: "icreate",
    name: "iCreate",
    case_study_title: "iCreate",
    img: icreate,
    text: "iCreate Africa is a hub for skill excellence and development. iCreate Africa is raising the profile and recognition of ...",
    case_study: {
      intro:
        "Bright Jaja was one of those listed in the prestigious Forbes 30 under 30, he has also won awards such as the Social Entrepreneurship and Digital Influencer award at the Soundcity MVP awards. On Friday, 28th of February, 2020, he launched his state of the art Artificial Intelligence, ICT, soft-skills training centre, incubation and acceleration hub, and co-working space called the iCreate Skills hub.",
    },
    challenges: [
      "The aim of this trend was to create awareness about the launch of the centre and get people to know of this whose aim is to create more skills for young ones.",
      "The secondary objective was to highlight some of Bright Jaja's previous achievements as himself and under his brand iCreate Africa. Both his personal and brand pages were constantly mentioned in the tweets so as to drive followers and conversation to his page.",
    ],
    process: {
      paragraph1: "",
      list: [],
      otherParagraphs: [
        "Effective strategies were created so as to gain maximum awareness for the contents and to ensure the goals of the campaign were met.",
      ],
    },
    results: [
      "From the analytics shown above, there were over 2300 tweets from 277 users on the #iCreateSkills hashtag. It amassed a 26.9 million impact and a reach of 3.5 million people.",
    ],
  },
  {
    id: 5,
    route_name: "clout africa",
    case_study_title: "clout africa",
    name: "Clout Africa",
    img: clout,
    text: "Clout Africa is Africa's first 360 music promotional audio-visual radio-digital platfor with the mission to produce ...",
    case_study: {
      intro:
        "Clout Africa is Africa's first 360 music promotional audio-visual radio-digital platform with the mission to produce, promote and connect Nigeria’s finest musical talents to a National, African & Worldwide audience. Clout was created to bridge the chasm between our talent and the global music market we seek to dominate. They offer a dynamic range of services that includes production, airplay and distribution because they possess world class facilities and the weight of the country’s most extensive media network.",
    },
    challenges: [
      "The Clout Africa reached out to us to increase the social media presence for the brand over the course of a month.",
    ],
    process: {
      paragraph1: "",
      list: [],
      otherParagraphs: [
        "During the month, the main focus was basically getting people to know and become familiar with the Clout NG brand by massively promoting the various #CloutLive sessions (Adekunle Gold, Fireboy DML and Praiz's sessions) through unique and effective strategies.",
      ],
    },
    results: [
      "Comparing the figures before and after the month, the impressions increased by about 900%, profile visits by 1700% and mentions, 5600%, while the followers rose by 478 during this period.",
    ],
  },
  {
    id: 6,
    route_name: "aux africa",
    case_study_title: "aux africa",
    name: "Aux Africa",
    img: aux,
    text: "Aux Africa is an audio-visual music and lifestyle platform featuring distinct, original content reachable and relatable across Africa and the world.",
    case_study: {
      intro:
        "Aux Africa is an audio-visual music and lifestyle platform featuring distinct, original content reachable and relatable across Africa and the world. It is a product of CoolFM 96.9 which is Nigeria’s number one hit music station. The aim of the platform was to showcase the most exceptional talent from Nigeria and Africa, focused on promoting the most distinctive new artistes and original sounds.",
    },
    challenges: [
      "Aux Africa’s aim was to utilize the power of new media to provide a unique audio-visual platform in the most minimalistic way. They reached out to us to increase their social media presence for the course of a month.",
    ],
    process: {
      paragraph1: "",
      list: [],
      otherParagraphs: [
        "During the month, the main focus was basically getting people to know and become familiar with the Aux NG brand mostly through the various #AuxSessions. The Aux Sessions of Fireboy DML, BlaqBonez & Oxlade, Funbi and Chike and were boosted through various effective strategies.",
        "Through this, we got artistes to register on the Aux platform through the Aux NG website. Also for event video content posted there were tweets directing the numerous videos to the Aux NG YouTube page. Therefore, new subscribers and a lot of video views were gotten.",
        "Also, during the month, an #AuxLive session held in which some major awareness and pre-event buzz was done. The Aux rehearsal sessions by the various artistes and the flyers for each individual artistes were posted up and strategically promoted . All these gained massive engagements which made more people get to know about the Aux Live event.",
      ],
    },
    results: [
      "There was a 415% increase in tweets, 1153% increase in tweet impressions, 574% increase in profile visits, 276% increase in mentions and a 86% increase in follower gain compared to that of the previous 28 day period.",
    ],
  },
];

import React from "react";
import { Link } from "react-router-dom";

const Contactus = () => {
  return (
    <div className="contact">
      <div className="contact-content container">
        <div className="contact-form">
          <form action="" autoComplete="off">
            <h1>
              Let's <span>talk</span> about <br /> your project
            </h1>

            <div className="input-control">
              <input type="text" name="name" placeholder="Name" required />
              <input
                type="text"
                name="Company Name"
                placeholder="Company Name"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                required
              />
              <input
                type="text"
                name="service"
                placeholder="service required"
                required
              />
              <input
                type="number"
                name="budget"
                placeholder="Budget"
                required
              />
              <input
                type="text"
                name="project"
                placeholder="Tell us more about your project"
                required
              />
            </div>
            <button type="submit">Reach out to us</button>
          </form>
        </div>
        <div className="contact-location">
          <h1>
            We are <span>here</span>
          </h1>
          <div className="contact-location-content">
            <div className="contact-info">
              <p>Email</p>
              <span>thecreativeally@gmail.com</span>
            </div>
            <div className="contact-info">
              <p>Call</p>
              <span>08179398521</span>
            </div>
            <div className="contact-info">
              <p>Social</p>
              <div className="contact-info-social">
                <a href="https://www.instagram.com/thecreativeally">
                  In<span>stagram</span>
                </a>
                <a href="https://twitter.com/TheCreativeAlly">
                  Tw<span>itter</span>
                </a>
                <Link to={"/"}>
                  Fa<span>cebook</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;

import React, { useRef } from "react";
import { ArrowDown } from "../icons";
import { useParams } from "react-router-dom";
import { clients } from "../data";
const SingleCaseStudy = () => {
  const { name } = useParams();
  const challengesRef = useRef(null);
  const processRef = useRef(null);
  const resultRef = useRef(null);

  const caseStudy = clients.filter((client) => client.route_name === name);

  window.scroll(0, 0);
  const title = caseStudy ? caseStudy[0].case_study_title : null;
  const intro = caseStudy ? caseStudy[0].case_study : null;
  const challenges = caseStudy ? caseStudy[0].challenges : null;
  const processes = caseStudy ? caseStudy[0].process : null;
  const results = caseStudy ? caseStudy[0].results : null;
  const logo = caseStudy ? caseStudy[0].img : null;

  return (
    <section className="case-study">
      {/* <header className="case-study-header"> */}
      <header
        className="case-study-header"
        style={{
          background: `linear-gradient(180deg,rgba(254, 119, 1, .95),rgba(254, 119, 1, .95)),url(${logo})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      >
        <div className="case-study-header-content container">
          <div>
            <h1>{title}</h1>
            <p>{intro.intro}</p>
            <button
              className="case-study-cta-btn"
              onClick={() =>
                challengesRef?.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              explore{" "}
              <span>
                <ArrowDown />
              </span>
            </button>
          </div>
        </div>
      </header>
      <div className="case-study-body">
        <article className="case-study-body-content">
          <div className="case-study-container">
            <h2 ref={challengesRef}>CHALLENGES</h2>

            {challenges.map((challenge, i) => {
              return <p key={i}>{challenge}</p>;
            })}

            <button
              className="case-study-cta-btn"
              onClick={() =>
                processRef?.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              Next
              <span>
                <ArrowDown />
              </span>
            </button>
          </div>
        </article>
        <article className="case-study-body-content case-study-body-content-bg">
          <div className="case-study-container">
            <h2 ref={processRef}>Process</h2>
            {processes.paragraph1 && <p>{processes.paragraph1}</p>}
            {processes.list.length > 0 && (
              <ul>
                {processes.list.map((list, i) => {
                  return <li key={i}>- {list}</li>;
                })}
              </ul>
            )}
            {processes.otherParagraphs.length > 0 &&
              processes.otherParagraphs.map((paragraph, i) => {
                return <p key={i}>{paragraph}</p>;
              })}
            <button
              className="case-study-cta-btn"
              onClick={() =>
                resultRef?.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              Next
              <span>
                <ArrowDown />
              </span>
            </button>
          </div>
        </article>
        <article className="case-study-body-content ">
          <div className="case-study-container">
            <h2 ref={resultRef}>Results</h2>
            {results.map((result, i) => {
              return <p key={i}>{result}</p>;
            })}
          </div>
        </article>
      </div>
    </section>
  );
};

export default SingleCaseStudy;

export const Twitter = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.4991C0 9.17775 9.17775 0 20.4991 0C31.8204 0 40.9982 9.17775 40.9982 20.4991C40.9982 31.8204 31.8204 40.9982 20.4991 40.9982C9.17775 40.9982 0 31.8204 0 20.4991ZM19.8851 16.6619L19.9281 17.3712L19.2112 17.2844C16.6016 16.9514 14.3217 15.8223 12.386 13.926L11.4396 12.9851L11.1959 13.6799C10.6797 15.2288 11.0095 16.8646 12.0849 17.9647C12.6584 18.5727 12.5294 18.6596 11.54 18.2977C11.1959 18.1819 10.8948 18.095 10.8661 18.1384C10.7657 18.2398 11.1099 19.5571 11.3823 20.0782C11.7551 20.802 12.515 21.5113 13.3467 21.9311L14.0493 22.264L13.2176 22.2785C12.4147 22.2785 12.386 22.293 12.472 22.597C12.7588 23.5379 13.8916 24.5367 15.1534 24.971L16.0424 25.275L15.2681 25.7382C14.121 26.4041 12.7731 26.7805 11.4253 26.8094C10.7801 26.8239 10.2495 26.8818 10.2495 26.9252C10.2495 27.07 11.9989 27.8807 13.0169 28.1991C16.071 29.14 19.6987 28.7347 22.4231 27.1279C24.3588 25.9843 26.2945 23.7116 27.1979 21.5113C27.6854 20.3388 28.1729 18.1963 28.1729 17.1686C28.1729 16.5027 28.2159 16.4158 29.0189 15.6196C29.492 15.1564 29.9365 14.6498 30.0226 14.505C30.166 14.23 30.1516 14.23 29.4204 14.4761C28.2016 14.9103 28.0295 14.8524 28.6317 14.201C29.0762 13.7378 29.6068 12.8982 29.6068 12.6521C29.6068 12.6087 29.3917 12.6811 29.1479 12.8113C28.8898 12.9561 28.3163 13.1732 27.8861 13.3035L27.1118 13.5496L26.4092 13.0719C26.0221 12.8113 25.4772 12.5218 25.1904 12.435C24.4592 12.2323 23.3408 12.2613 22.6812 12.4929C20.8888 13.1443 19.7561 14.8235 19.8851 16.6619Z"
        fill="#8F8F8F"
        className="svg"
      />
    </svg>
  );
};
export const Instagram = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.4991C0 9.17775 9.17775 0 20.4991 0C31.8204 0 40.9982 9.17775 40.9982 20.4991C40.9982 31.8204 31.8204 40.9982 20.4991 40.9982C9.17775 40.9982 0 31.8204 0 20.4991ZM20.5001 9.56624C17.5309 9.56624 17.1583 9.57922 15.9921 9.63229C14.8282 9.68559 14.0337 9.86985 13.3386 10.1402C12.6195 10.4195 12.0095 10.793 11.4018 11.4009C10.7937 12.0086 10.4201 12.6186 10.14 13.3374C9.86894 14.0328 9.68445 14.8275 9.63206 15.9909C9.5799 17.1571 9.56624 17.53 9.56624 20.4992C9.56624 23.4684 9.57945 23.8399 9.63229 25.0061C9.68581 26.17 9.87008 26.9644 10.1402 27.6596C10.4197 28.3787 10.7932 28.9886 11.4011 29.5963C12.0086 30.2045 12.6186 30.5789 13.3372 30.8582C14.0328 31.1285 14.8275 31.3128 15.9912 31.3661C17.1574 31.4192 17.5298 31.4321 20.4987 31.4321C23.4682 31.4321 23.8397 31.4192 25.0058 31.3661C26.1698 31.3128 26.9651 31.1285 27.6607 30.8582C28.3796 30.5789 28.9886 30.2045 29.5961 29.5963C30.2042 28.9886 30.5778 28.3787 30.8579 27.6598C31.1267 26.9644 31.3112 26.1698 31.3659 25.0063C31.4182 23.8401 31.4319 23.4684 31.4319 20.4992C31.4319 17.53 31.4182 17.1574 31.3659 15.9912C31.3112 14.8273 31.1267 14.0328 30.8579 13.3376C30.5778 12.6186 30.2042 12.0086 29.5961 11.4009C28.9879 10.7928 28.3798 10.4192 27.66 10.1402C26.9631 9.86985 26.1682 9.68559 25.0043 9.63229C23.8381 9.57922 23.4668 9.56624 20.4967 9.56624H20.5001Z"
        fill="#8F8F8F"
        className="svg"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5193 11.5364C19.8104 11.536 20.1352 11.5364 20.5001 11.5364C23.4192 11.5364 23.7652 11.5469 24.9179 11.5993C25.9839 11.6481 26.5624 11.8262 26.9478 11.9758C27.458 12.174 27.8218 12.4109 28.2042 12.7935C28.5868 13.1762 28.8237 13.5406 29.0223 14.0508C29.172 14.4357 29.3503 15.0143 29.3988 16.0802C29.4512 17.2327 29.4626 17.579 29.4626 20.4967C29.4626 23.4144 29.4512 23.7606 29.3988 24.9131C29.3501 25.9791 29.172 26.5576 29.0223 26.9426C28.8242 27.4528 28.5868 27.8161 28.2042 28.1985C27.8215 28.5811 27.4582 28.818 26.9478 29.0162C26.5629 29.1665 25.9839 29.3442 24.9179 29.3929C23.7654 29.4453 23.4192 29.4567 20.5001 29.4567C17.5808 29.4567 17.2348 29.4453 16.0823 29.3929C15.0163 29.3437 14.4378 29.1656 14.0522 29.016C13.542 28.8178 13.1775 28.5809 12.7949 28.1983C12.4122 27.8156 12.1753 27.4521 11.9767 26.9417C11.8271 26.5567 11.6487 25.9782 11.6002 24.9122C11.5478 23.7597 11.5374 23.4135 11.5374 20.494C11.5374 17.5744 11.5478 17.23 11.6002 16.0775C11.649 15.0115 11.8271 14.433 11.9767 14.0476C12.1749 13.5374 12.4122 13.173 12.7949 12.7903C13.1775 12.4077 13.542 12.1708 14.0522 11.9722C14.4376 11.8218 15.0163 11.6442 16.0823 11.5952C17.0908 11.5497 17.4817 11.536 19.5193 11.5337V11.5364ZM26.336 13.3518C25.6117 13.3518 25.0241 13.9387 25.0241 14.6633C25.0241 15.3876 25.6117 15.9752 26.336 15.9752C27.0603 15.9752 27.648 15.3876 27.648 14.6633C27.648 13.939 27.0603 13.3518 26.336 13.3518ZM20.5001 14.8847C17.3995 14.8847 14.8856 17.3986 14.8856 20.4992C14.8856 23.5998 17.3995 26.1126 20.5001 26.1126C23.6007 26.1126 26.1137 23.5998 26.1137 20.4992C26.1137 17.3986 23.6007 14.8847 20.5001 14.8847Z"
        fill="#8F8F8F"
        className="svg"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5001 16.8549C22.5127 16.8549 24.1444 18.4864 24.1444 20.4992C24.1444 22.5118 22.5127 24.1435 20.5001 24.1435C18.4873 24.1435 16.8558 22.5118 16.8558 20.4992C16.8558 18.4864 18.4873 16.8549 20.5001 16.8549Z"
        fill="#8F8F8F"
        className="svg"
      />
    </svg>
  );
};
export const Facebook = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.4991C0 9.17775 9.17775 0 20.4991 0C31.8204 0 40.9982 9.17775 40.9982 20.4991C40.9982 31.8204 31.8204 40.9982 20.4991 40.9982C9.17775 40.9982 0 31.8204 0 20.4991ZM22.6357 32.5521V21.3995H25.7143L26.1223 17.5563H22.6357L22.641 15.6327C22.641 14.6303 22.7362 14.0932 24.1759 14.0932H26.1005L26.1005 10.2495H23.0215C19.3231 10.2495 18.0213 12.1139 18.0213 15.2492V17.5567H15.716V21.3999H18.0213V32.5521L22.6357 32.5521Z"
        fill="#8F8F8F"
        className="svg"
      />
    </svg>
  );
};
export const Youtube = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.4991C0 9.17775 9.17775 0 20.4991 0C31.8204 0 40.9982 9.17775 40.9982 20.4991C40.9982 31.8204 31.8204 40.9982 20.4991 40.9982C9.17775 40.9982 0 31.8204 0 20.4991ZM30.975 15.4368C30.7235 14.4708 29.9826 13.7101 29.0417 13.4519C27.3367 12.9827 20.4991 12.9827 20.4991 12.9827C20.4991 12.9827 13.6615 12.9827 11.9563 13.4519C11.0155 13.7101 10.2745 14.4708 10.0231 15.4368C9.56624 17.1877 9.56624 20.8407 9.56624 20.8407C9.56624 20.8407 9.56624 24.4937 10.0231 26.2446C10.2745 27.2106 11.0155 27.9713 11.9563 28.2296C13.6615 28.6987 20.4991 28.6987 20.4991 28.6987C20.4991 28.6987 27.3367 28.6987 29.0417 28.2296C29.9826 27.9713 30.7235 27.2106 30.975 26.2446C31.4319 24.4937 31.4319 20.8407 31.4319 20.8407C31.4319 20.8407 31.4319 17.1877 30.975 15.4368Z"
        fill="#8F8F8F"
        className="svg"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4492 24.5989V17.7659L23.9156 21.1825L18.4492 24.5989Z"
        fill="#8F8F8F"
        className="svg"
      />
    </svg>
  );
};
export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
      />
    </svg>
  );
};
export const ArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};
export const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};
export const Phone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
      />
    </svg>
  );
};

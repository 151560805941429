import React, { useEffect } from "react";
import Hero from "../component/Hero";
import { ClientsProject } from "../component/ClientsProject";
import Services from "../component/Services";
import Works from "../component/Works";
import "../styles/Home.css";
import { motion, useIsPresent } from "framer-motion";
import { useLocation } from "react-router-dom";
import Gallery from "../component/Gallery";
import ClientsTestimonials from "../component/ClientsTestimonials";

const Home = () => {
  const location = useLocation();
  const isPresent = useIsPresent();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <motion.div>
      <Hero />
      <ClientsProject />
      <Gallery />
      <Services />
      <Works />
      <ClientsTestimonials />
      <motion.div
        className="privacy-screen"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
      ></motion.div>
    </motion.div>
  );
};

export default Home;

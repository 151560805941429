import React, { useEffect } from "react";
import "../styles/CaseStudies.css";
import { motion, useAnimation, useIsPresent } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { staggerContainer, fadeIn, fadeUp } from "../animation";
import { Link } from "react-router-dom";
import { clients } from "../data";

const CaseStudies = () => {
  const isPresent = useIsPresent();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0.5, 0.75, 0.95],
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start("onscreen");
    }
    if (!inView) {
      animation.start("offscreen");
    }
  }, [animation, inView]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="case-studies">
        <motion.div
          ref={ref}
          initial="offscreen"
          variants={staggerContainer}
          animate={animation}
          className="case-studies-hero"
        >
          <div className="container">
            <motion.h1 variants={fadeUp}>Case Studies</motion.h1>
            <motion.p variants={fadeIn}>
              We are The Creative Ally, a social media agency that focuses on
              brand narrative as a means to provide value addition in the
              digital space.
            </motion.p>
          </div>
        </motion.div>
        <section className="cs-cards-section">
          {clients.map((client, index) => {
            return (
              <div
                key={index}
                className={`clients case-studies-card-wrapper cs-cw${
                  index + 1
                }`}
              >
                <div className="client-cards">
                  <div className="client-card">
                    <img src={client.img} alt={client.name} />
                    <p>{client.text}</p>
                    <Link
                      to={`/case-study/${client.route_name}`}
                      className="cc-cta-btn"
                    >
                      view case study
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </section>
      <motion.div
        className="privacy-screen"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
      ></motion.div>
    </>
  );
};

export default CaseStudies;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "../styles/slider.css";
import { Parallax, Pagination, Navigation, Autoplay } from "swiper";
import { clients } from "../data";
import { Link } from "react-router-dom";

export const ClientsProject = () => {
  return (
    <section className="clients-project-section">
      <Swiper
        style={{
          "--swiper-navigation-size": "20px",
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        speed={600}
        parallax={true}
        autoplay={{ delay: 5000 }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Parallax, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        <div
          slot="container-start"
          className="parallax-bg"
          data-swiper-parallax="-23%"
        ></div>
        {clients.map((client, i) => {
          return (
            <SwiperSlide>
              <article
                key={client}
                className={`creative-project-card cpc-${i + 1}`}
              >
                <div data-swiper-parallax="-300">
                  <img
                    className="project-logo"
                    src={client.img}
                    alt={client.name}
                  />
                </div>
                <p data-swiper-parallax="-200">{client.text}</p>
                <div data-swiper-parallax="-100">
                  <Link
                    to={`/case-study/${client.route_name}`}
                    className="cc-cta-btn"
                  >
                    view case study
                  </Link>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

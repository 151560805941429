import React, { useEffect } from "react";
import basketmouth from "../asset/comedy-central/cc-main2.JPG";
import blaqbones from "../asset/comedy-central/cc-main3.JPG";
import basketblaq from "../asset/comedy-central/cc-main1.JPG";
import basketboi from "../asset/comedy-central/cc-main4.JPG";
import ss1 from "../asset/comedy-central/news-ss1.PNG";
import ss2 from "../asset/comedy-central/news-ss2.PNG";
import ss3 from "../asset/comedy-central/news-ss3.PNG";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { staggerContainer, slideIn, slideInPos } from "../animation";

const Gallery = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0.15, 0.75, 0.95],
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start("onscreen");
    }
    if (!inView) {
      animation.start("offscreen");
    }
  }, [animation, inView]);
  return (
    <section className="clients-gallery-section">
      <motion.div
        className="client-gallery-wrapper"
        ref={ref}
        initial="offscreen"
        variants={staggerContainer}
        animate={animation}
      >
        <div className="clients-galleries">
          <motion.div variants={slideIn} className="client-gallery cg1">
            <img src={basketmouth} alt="basketmouth" />
          </motion.div>
          <motion.div variants={slideInPos} className="client-gallery cg2">
            <img src={blaqbones} alt="blaqbones" />
          </motion.div>
          <motion.div variants={slideIn} className="client-gallery cg3">
            <img src={basketblaq} alt="basketmouth&blaqbones" />
          </motion.div>
          <motion.div variants={slideInPos} className="client-gallery cg4">
            <img src={basketboi} alt="basketmouth" />
          </motion.div>
        </div>
      </motion.div>
      <div className="client-gallery-texts">
        <p className="c-g-text">
          The Creative Ally created the unique copywriting for the press release
          of the collaboration between Basketmouth and Comedy Central, with the
          aim to get readers engaged through storytelling.
        </p>
        <p>
          By the means of Creative's Ally strategic strategic collaboration with
          prolific publications (both Nigerian and international). The press
          release was published on The guardian Digital print and social media.
        </p>
      </div>
      <div className="client-gallery-wrapper">
        <div className="news-galleries">
          <div className="client-gallery news-img1">
            <img src={ss3} alt="screenshot" />
          </div>
          <div className="client-gallery news-img2">
            <img src={ss2} alt="screenshot" />
          </div>
          <div className="client-gallery news-img3">
            <img src={ss1} alt="screenshot" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;

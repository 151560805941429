import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../asset/logoA.png";
import call from "../asset/call.svg";
import search from "../asset/search.svg";
import "../styles/MobileNavigation.css";

const MobileMenu = ({ isOpen, setIsOpen }) => {
  return (
    <div className="mobile-nav container">
      <div className="mobile-menu-header">
        {/* logo */}
        <Link to="/" className="logo">
          <img src={logo} alt="logo" onClick={() => setIsOpen(false)} />
        </Link>
        {/* hamburger */}
        <button
          className={`${
            isOpen
              ? "hamburger hamburger--emphatic is-active"
              : "hamburger hamburger--emphatic"
          }`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
      {/* navigation */}
      <nav className={`${isOpen ? "navigation is-open" : "navigation"}`}>
        <ul className="navigation-list">
          <li className="navigation-list-item">
            <NavLink to="/about" onClick={() => setIsOpen(false)}>
              About Us
            </NavLink>
          </li>
          <li className="navigation-list-item">
            <NavLink to="/services" onClick={() => setIsOpen(false)}>
              What We Do
            </NavLink>
          </li>
          <li className="navigation-list-item">
            <NavLink to="/case-studies" onClick={() => setIsOpen(false)}>
              Case Studies
            </NavLink>
          </li>
          <li className="navigation-list-item">
            <NavLink to="/contact-us" onClick={() => setIsOpen(false)}>
              Contact Us
            </NavLink>
          </li>
          <li className="navigation-list-item">
            <NavLink to="/blog" onClick={() => setIsOpen(false)}>
              Blog
            </NavLink>
          </li>
        </ul>
        <div className="nav-actions">
          <div className="nav-actions-col1">
            {/* <a href="tel: 08132456789">
              <img src={call} alt="call" />
            </a>{" "} */}
            {/* | <img src={search} alt="search" className="search" /> */}
          </div>
          <a href="mailto:thecreativeally@gmail.com" className="cta-btn">
            Request Consultation
          </a>
        </div>
      </nav>
    </div>
  );
};

export default MobileMenu;

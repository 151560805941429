import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../asset/logoA.png";
import call from "../asset/call.svg";
import search from "../asset/search.svg";
import "../styles/DesktopMenu.css";
import { Phone } from "../icons";

const DesktopMenu = () => {
  return (
    <div className="desktop-menu ">
      <div className="desktop-menu-header container">
        {/* logo */}
        <Link to="/" className="xl-logo logo">
          <img src={logo} alt="logo" />
        </Link>
        {/* navigation */}
        <nav className="desktop-main-nav">
          <ul className="xl-navigation-list">
            <li className="xl-navigation-list-item">
              <NavLink to="/about">About Us</NavLink>
            </li>
            <li className="xl-navigation-list-item">
              <NavLink to="/services">Services</NavLink>
            </li>
            <li className="xl-navigation-list-item">
              <NavLink to="/case-studies">Case Studies</NavLink>
            </li>
            <li className="xl-navigation-list-item">
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
            <li className="xl-navigation-list-item">
              <NavLink to="/blog">Blog</NavLink>
            </li>
          </ul>
        </nav>
        {/* cta */}
        <div className="xl-nav-actions">
          <a href="tel: 08132456789" title="call">
            <Phone />
          </a>
          {/* <div className="search-wrapper">
            <img src={search} alt="search" className="search" />
          </div> */}
          <div className="xl-btn-wrapper">
            <a href="mailto:thecreativeally@gmail.com" className="xl-cta-btn">
              Request Consultation
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopMenu;

export const testimonials = [
  {
    id: 1,
    testimonial: [
      "In communications, it is critical to work with vendors that are meticulousbecause one line of error breaks the chain of a campaign. The carefulness oftheir execution is what continues to draw us to them. They are able to executeon a brief with almost no supervision.",
    ],
    name: "Victor Okpala",
    position: "Founder/ CEO Nabsolute Media",
  },
  {
    id: 2,
    testimonial: [
      "Having worked with The Creative Ally team on past projects, I can confidently say that they are an asset to any team or campaign they join. They bring a new insight and approach to every project. I would alwaysrecommend them for jobs centering on digital marketing strategies and execution",
    ],
    name: "Odi Francis",
    position: "Digital Marketing Lead, Adron Homes and Properties Ltd",
  },
  {
    id: 3,
    testimonial: [
      "I love working with The Creative Ally, they are efficient , reliable and consistent and that is something we can always bank on. Even when we get late approvals for campaign and client jobs , it’s comforting to know that we have a partner like The Creative Ally and they will go the extra mile to deliver.",
    ],
    name: "Tonye Ekine",
    position: "Chief Marketing Officer, AGI Media",
  },
  {
    id: 4,
    testimonial: [
      "ShawnIfe was a Digital Marketer who worked with me on a campaign when I was Brand manager of a fashion e-commerce business. When it came down to analyzing the data, Shawn brought results. The way he put himself into achieving the campaign goals definitely stood him out and that was unique.",
    ],
    name: "Oluwaseyi Catherine Tomosori",
    position: "Senior Analyst at Insight Redefini",
  },
  {
    id: 5,
    testimonial: [
      "When I moved on to an agency to lead, I made sure every digital campaign we had a need for, passed through him and The Creative Ally. ShawnIfe brings good results every time. He is so professional and I am definitely recommending him to everyone who cares to achieve ROI or cares to meet campaign goals for their campaigns.",
    ],
    name: "Oluwaseyi Catherine Tomosori",
    position: "Senior Analyst at Insight Redefini",
  },
];

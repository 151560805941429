import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import error from "../asset/404.svg";
import "../styles/Error.css";

const Error = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <section className="error-page">
      <div>
        <img src={error} alt="error" />
        <Link to={"/"}>Go Home</Link>
      </div>
    </section>
  );
};

export default Error;

import React from "react";
import logo from "../asset/logoB.svg";
import { Facebook, Instagram, Twitter, Youtube } from "../icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content container">
        <div className="footer-text-content-container">
          <div className="footer-text-content">
            <div className="footer-logo">
              <img src={logo} alt="logo" />
            </div>
            <p>
              We are The Creative Ally, a social media agency that focuses on
              brand narrative as a means to provide value addition in the
              digital space.
            </p>
            <div className="socials">
              <a href="https://twitter.com/TheCreativeAlly" title="twitter">
                <Twitter />
              </a>
              <a
                href="https://www.instagram.com/thecreativeally"
                title="instagram"
              >
                <Instagram />
              </a>
              <Link to="#" title="facebook">
                <Facebook />
              </Link>
              <Link to="#" title="youtube" className="youtube">
                <Youtube />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-attr-content">
          <ul className="quick-links-list">
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/services">What We Do</Link>
            </li>
            <li>
              <Link to="/case-studies">Case Studies</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <div className="copyright">
            <p>© Copyright {new Date().getFullYear()} | The Creative Ally</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

export const staggerContainer = {
  offscreen: {},
  onscreen: {
    transition: {
      staggerChildren: 0.35,
      ease: "easeInOut",
    },
  },
};

export const fadeIn = {
  offscreen: {
    y: 40,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
};
export const fadeUp = {
  offscreen: {
    y: -40,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
};
export const slideIn = {
  offscreen: {
    x: -40,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
};
export const slideInPos = {
  offscreen: {
    x: 40,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
};
export const zoomIn = {
  offscreen: {
    scale: 0,
    opacity: 0,
  },
  onscreen: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
};

export const animatePages = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const transition = {
  duration: 0.35,
};

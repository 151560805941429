import React, { useEffect } from "react";
import "../styles/Blog.css";
import img from "../asset/under_construction.svg";
import { motion, useIsPresent } from "framer-motion";

const Blog = () => {
  const isPresent = useIsPresent();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="blog-section">
      <div>
        <img src={img} alt="under_construction" />
        <p>Coming Soon</p>
      </div>
      <motion.div
        className="privacy-screen"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
      ></motion.div>
    </section>
  );
};

export default Blog;
